const awsConfig = {
    aws_project_region: "ap-southeast-2",
    aws_appsync_region: "ap-southeast-2",
    aws_cognito_region: "ap-southeast-2",
    aws_user_pools_id: "ap-southeast-2_HCqb3tWoj",
    aws_user_pools_web_client_id: "57g4fn141d9scm8cebuhka9i78",
    oauth: {
      domain: "practitioner-staging.auth.ap-southeast-2.amazoncognito.com",
      scope: [
          "openid",
          "profile"
      ],
      redirectSignIn: "https://practitioner-staging.mydna.life/welcome",
      redirectSignOut: "https://practitioner-staging.mydna.life/end",
      responseType: "code"
    },
    federationTarget: "COGNITO_USER_POOLS"
  };
  export default awsConfig;